export class Picture {
    id: any;
    filename: string;
    description: number;
    base64Image: string;

    constructor(fields: any) {
        this.id = fields.id;
        this.description = fields.description;
        this.filename = fields.filename;
        this.base64Image = fields.base64Image;
    }
}

export interface Picture {
    [prop: string]: any;
}
