export class Item {
  id : any ;
  nom : string ;
  zone : any ;
  note: any ;

  constructor(fields: any) {
    this.id = fields.id;
    this.nom = fields.nom;
    this.zone = fields.zone;
  }
}

export interface Item {
    [prop: string]: any;
}
