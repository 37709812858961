export class Queue {
    method: string;
    endpoint: string;
    body?: any;
    reqOpts?: any;

    constructor(method: string, endpoint: string, body?: any, reqOpts?: any) {
        this.method = method;
        this.endpoint = endpoint;
        this.body = body;
        this.reqOpts = reqOpts;
    }
}
