import {Injectable} from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import {UserProvider} from '../user/user';
import {Storage} from '@ionic/storage-angular';
import {ONESIGNAL_APP_ID} from '../../../environments/common';
import {APP_SHORT_NAME} from '../../../environments/environment';
import {Capacitor} from '@capacitor/core';

@Injectable({providedIn: 'root'})
export class NotificationProvider {
    available: boolean;

    constructor(private userProvider: UserProvider, private storage: Storage) {
        this.available = false;

        if (Capacitor.isNative) {
            this.available = true;
        }
    }

    load() {
        if (this.available) {
            OneSignal.setLogLevel(6, 0);
            console.log('Load OneSignal');
            // NOTE: Update the setAppId value below with your OneSignal AppId.
            OneSignal.setAppId(ONESIGNAL_APP_ID[APP_SHORT_NAME]);

            OneSignal.setNotificationOpenedHandler(jsonData => {
                window.location.reload();
                console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
            });

            OneSignal.setNotificationWillShowInForegroundHandler(e => {

            });

            // iOS - Prompts the user for notification permissions.
            OneSignal.promptForPushNotificationsWithUserResponse(accepted => {
                console.log('User accepted notifications: ' + accepted);
            });
        }
    }

    async syncUserData() {
        console.log('sync notif data');
        if (this.available) {
            const userToken = await this.storage.get('userToken');
            await this.userProvider.getUserData(userToken);

            OneSignal.setEmail(this.userProvider.user.username);
            OneSignal.setExternalUserId(this.userProvider.user.id.toString());
        }
    }
}
