import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'homepage',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'liste-controle',
        loadChildren: () => import('./liste-controle/liste-controle.module').then(m => m.ListeControlePageModule)
    },
    {
        path: 'controle',
        loadChildren: () => import('./controle/controle.module').then(m => m.ControlePageModule)
    },
    {
        path: 'fin-controle',
        loadChildren: () => import('./fin-controle/fin-controle.module').then(m => m.FinControlePageModule)
    },
    {
        path: 'bravo',
        loadChildren: () => import('./bravo/bravo.module').then(m => m.BravoPageModule)
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
