export class Photo {
    id: any;
    nom: number;

    constructor(fields: any) {
        this.id = fields.id;
        this.nom = fields.nom;
    }
}

export interface Photo {
    [prop: string]: any;
}
