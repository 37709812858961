import {HttpHeaders} from '@angular/common/http';

import {Injectable} from '@angular/core';

import {Api} from '../api/api';
import {User} from '../../models';
import {APP_NAME} from '../../../environments/environment';
import {Storage} from '@ionic/storage-angular';

/**
 * Most apps have the concept of a User. This is a simple provider
 * with stubs for login/signup/etc.
 *
 * This User provider makes calls to our API at the `login` and `signup` endpoints.
 *
 * By default, it expects `login` and `signup` to return a JSON object of the shape:
 *
 * ```json
 * {
 *   status: 'success',
 *   user: {
 *     // User fields your app needs, like "id", "name", "email", etc.
 *   }
 * }
 * ```
 *
 * If the `status` field is not `success`, then an error is detected and returned.
 */
@Injectable({providedIn: 'root'})
export class UserProvider {
    user: User;
    isResolved: boolean;
    userToken: string;

    constructor(public api: Api, private storage: Storage) {
        this.storage.create();
    }


    /**
     * Send a POST request to our login endpoint with the data
     * the user entered on the form.
     */
    login(accountInfo: any) {

        return new Promise((resolve, reject) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            };
            const accountParam = Object.keys(accountInfo).map(key => `${key}=${accountInfo[key]}`).join('&');
            this.api.post('login_check', accountParam, httpOptions)
                .then(
                    async (res: any) => {
                        await this._loggedIn(res);
                        resolve();
                    }, (err: any) => {
                        console.error('ERROR', JSON.stringify(err));
                        reject();
                    });
        });
    }

    async patch(body: any, token: string) {
        this.userToken = await this.getToken();

        return new Promise((resolve, reject) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `application/json`,
                    'X-Api-Solution': APP_NAME
                })
            };
            this.api.patch('user/', body, httpOptions)
                .then(
                    () => {
                        // If the API returned a successful response, mark the user as logged in
                        resolve();
                    },
                    (err: any) => {
                        console.error('ERROR', err);
                        reject();
                    }
                );
        });
    }

    /**
     * Return the JWT Token from the Remote Server API
     */
    async getToken() {
        return this.storage.get('userToken');
    }


    /**
     * Process a login/signup response to store user data
     */
    async _loggedIn(resp) {
        await this.storage.set('refreshToken', resp.refresh_token);
        await this.storage.set('userToken', resp.token);

        console.log('logged in', await this.storage.get('userToken'));
    }

    async getAddressesReminders() {
        this.userToken = await this.getToken();

        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${this.userToken}`,
                'Content-Type': `application/json`,
                'X-Api-Solution': APP_NAME
            })
        };

        return this.api.get('adresse/remind', null, httpOptions)
            .then((res: any) => {
                return res;
            });
    }

    async getUserData(params: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${params}`,
                'Content-Type': `application/json`,
                'X-Api-Solution': APP_NAME
            })
        };

        return this.api.get('user/me/', null, httpOptions)
            .then(
                (res: any) => {
                    // If the API returned a successful response, mark the user as logged in
                    this.user = new User(res);
                    return this.user;
                });
    }

    async getUserGoal(params = null) {
        this.userToken = await this.getToken();

        return new Promise((resolve, reject) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${this.userToken}`,
                    'Content-Type': `application/json`,
                    'X-Api-Solution': APP_NAME
                })
            };

            if (this.user.roles.includes('ROLE_AGENT')) {
                this.api.get('user/objectif', params, httpOptions)
                    .then(
                        (res: any) => {
                            resolve(res);
                        },
                        (err: any) => {
                            console.error('ERROR', err);
                            reject();
                        }
                    );
            } else if (this.user.roles.includes('ROLE_MANAGER')) {
                this.api.get('user/objectif/manager', params, httpOptions)
                    .then(
                        (res: any) => {
                            resolve(res);
                        },
                        (err: any) => {
                            console.error('ERROR', err);
                            reject();
                        }
                    );
            } else if (this.user.roles.includes('ROLE_SUPERAGENT')) {
                this.api.get('user/objectif/superagent', params, httpOptions)
                    .then(
                        (res: any) => {
                            resolve(res);
                        },
                        (err: any) => {
                            console.error('ERROR', err);
                            reject();
                        }
                    );
            } else {
                console.log('no agent role, return false');
                resolve(false);
            }
        });
    }

    async loginFromRefreshToken(refreshToken: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return await this.api.post('token/refresh', {refresh_token: refreshToken}, httpOptions)
            .then(
                (res: any) => {
                    this._loggedIn(res);
                }, (err: any) => {
                    console.error('ERROR', err);
                });
    }
}
