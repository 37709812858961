export class User {
    id: number;
    username: string;
    enabled: boolean;
    nom: string;
    prenom: string;
    client: any;
    addressesAccess: any;
    roles: any;
    lastControlDate: any;

    constructor(fields: any) {
        this.id = fields.id;
        this.username = fields.username;
        this.enabled = fields.enabled;
        this.nom = fields.nom;
        this.prenom = fields.prenom;
        this.client = fields.client;
        this.addressesAccess = fields.addressesAccess
        this.roles = fields.array_roles;
        this.lastControlDate = fields.last_control_date;
    }
}

export interface User {
    [prop: string]: any;
}
