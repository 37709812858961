import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {Observable, from, of, forkJoin, BehaviorSubject} from 'rxjs';
import {switchMap, finalize} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ToastController} from '@ionic/angular';
import {APP_NAME, SERVER_URL} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OfflineManagerService {
    private itemsInQueue: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor(
        private storage: Storage,
        private http: HttpClient,
        private toastController: ToastController) {
        this.storage.create();

        this.storage.get('queue').then(queue => {
            if (queue) {
                this.itemsInQueue.next(queue);
            }
        });
    }

    onQueueChange(): Observable<any> {
        return this.itemsInQueue.asObservable();
    }

    async addToQueue(item) {
        const queue: [] = await this.storage.get('queue') || [];
        this.itemsInQueue.next(queue.length + 1);
        return this.storage.set('queue', [...queue, JSON.stringify(item)]);
    }

    checkForEvents(): Observable<any> {
        return from(this.storage.get('queue')).pipe(
            switchMap(storedData => {
                const storedObj = storedData;

                if (storedObj) {
                    const rs = this.sendRequests(storedObj);

                    rs.then(res => {
                        return res.pipe(
                            finalize(() => {
                                const toast = this.toastController.create({
                                    message: `Les données ont bien été synchronisées`,
                                    duration: 3000,
                                    position: 'bottom'
                                });
                                toast.then(resp => resp.present());

                                this.storage.remove('queue');
                            })
                        );
                    });
                } else {
                    console.log('no local events to sync');
                    return of(false);
                }
            })
        );
    }

    async sendRequests(operations: []) {
        const obs = [];

        for (const item of operations) {
            if (item) {
                const op = JSON.parse(item);
                console.log(op);
                const userToken = await this.storage.get('userToken');

                const oneObs = this.http.request(op.method, `${SERVER_URL}/api/` + op.endpoint, {
                    headers: new HttpHeaders({
                        Authorization: `Bearer ` + userToken,
                        'Content-Type': `application/json`,
                        'X-Api-Solution': APP_NAME
                    }), body: op.body
                });
                obs.push(oneObs);
            }
        }

        // Send out all local events and return once they are finished
        return forkJoin(obs);
    }
}
