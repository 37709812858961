import {Injectable} from '@angular/core';
import {Plugins} from '@capacitor/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ToastController, Platform} from '@ionic/angular';

const {Network} = Plugins;

export enum ConnectionStatus {
    Online,
    Offline
}

@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);

    private network = Network;

    private currentStatus = ConnectionStatus.Offline;

    constructor(private toastController: ToastController, private plt: Platform) {
        this.plt.ready().then(() => {
            this.initializeNetworkEvents();

            this.network.getStatus().then(resp => {
                const status = resp.connected ? ConnectionStatus.Online : ConnectionStatus.Offline;
                this.currentStatus = status;
                this.status.next(status);
            });
        });
    }

    public initializeNetworkEvents() {
        this.network.addListener('networkStatusChange', (status) => {
            console.log('Network status changed', status.connected, status.connectionType);
            if (status.connected === true) {
                this.updateNetworkStatus(ConnectionStatus.Online);
            } else {
                this.updateNetworkStatus(ConnectionStatus.Offline);
            }
        });
    }

    private async updateNetworkStatus(status: ConnectionStatus) {
        this.status.next(status);

        const connection = status === ConnectionStatus.Offline ? 'déconnecté' : 'connecté';
        const toast = this.toastController.create({
            message: `Vous êtes en mode ${connection}`,
            duration: 3000,
            position: 'bottom'
        });

        if (this.currentStatus !== status) {
            toast.then(resp => resp.present());
            this.currentStatus = status;
        }
    }

    public onNetworkChange(): Observable<ConnectionStatus> {
        return this.status.asObservable();
    }

    public getCurrentNetworkStatus(): ConnectionStatus {
        return this.status.getValue();
    }
}
