import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment, fillAppInfos} from './environments/environment';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import { Capacitor } from '@capacitor/core';

if (environment.production) {
    enableProdMode();
}

fillAppInfos().then(() => {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
});

defineCustomElements(window);
