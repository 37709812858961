import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Platform} from '@ionic/angular';
import {ConnectionStatus, NetworkService} from './services/network.service';
import {OfflineManagerService} from './services/offline-manager.service';
import {Storage} from '@ionic/storage-angular';
import {Plugins, Capacitor, StatusBarStyle} from '@capacitor/core';
import {APP_SHORT_NAME} from '../environments/environment';
import {UserProvider} from './services/user/user';
import {NotificationProvider} from './services/notification/notification';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    itemInQueue = false;
    appShortName = null;

    constructor(
        private userProvider: UserProvider,
        private platform: Platform,
        private storage: Storage,
        private offlineManager: OfflineManagerService,
        private networkService: NetworkService,
        private notificationProvider: NotificationProvider
    ) {
    }

    ngOnInit() {
        this.initializeApp();
    }

    initializeApp() {
        this.appShortName = APP_SHORT_NAME;

        this.notificationProvider.load();

        this.platform.ready().then(() => {
            const {SplashScreen, StatusBar} = Plugins;

            if (Capacitor.isNative) {
                StatusBar.setStyle({style: StatusBarStyle.Light});
            }
            SplashScreen.hide();

            this.offlineManager.onQueueChange().subscribe((nbItems: number) => {
                this.itemInQueue = nbItems > 0;
            });

            this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
                console.log('Network change');
                if (status === ConnectionStatus.Online) {
                    this.offlineManager.checkForEvents().subscribe({
                        complete: () => {
                            this.itemInQueue = false;
                        }
                    });
                }
            });
        });
    }
}
