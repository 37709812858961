export class Note {
    id: any;
    controle: any;
    note: number;
    commentaire: string;
    photo: any;
    checked: boolean;
    pictures: [];

    constructor(fields: any) {
        this.id = fields.id;
        this.controle = fields.controle;
        this.note = fields.note;
        this.checked = fields.checked;
        this.commentaire = fields.commentaire;
        this.pictures = fields.pictures;
    }
}

export interface Note {
    [prop: string]: any;
}
