export class Adresse {
    id: number;
    voie: string;
    numero: string;
    repetition: string;
    codePostal: string;
    ville: string;
    batiment: string;
    residence: string;
    numeroAscenceur: string;
    items: any;

    constructor(fields: any) {
        this.id = fields.id;
        this.voie = fields.voie;
        this.numero = fields.numero;
        this.repetition = fields.repetition;
        this.codePostal = fields.codePostal;
        this.ville = fields.ville;
        this.batiment = fields.batiment;
        this.residence = fields.residence;
        this.numeroAscenceur = fields.numeroAscenceur;
        this.items = fields.items;
    }
}

export interface Adresse {
    [prop: string]: any;
}
