export class Controle {
    id: number;
    adresse: any;
    dateProgrammee: string;
    dateRealisation: string;
    valide: boolean;
    commentaireGeneral: string;
    itemsControles: any;
    rapport: boolean;
    alerteFournisseur: boolean;
    isContradictory: boolean;
    contradictoryPerson: string;
    contradictoryPersonDetail: string;

    constructor(fields: any) {
        this.id = fields.id;
        this.dateProgrammee = fields.date_programmee;
        this.dateRealisation = fields.date_realisation;
        this.commentaireGeneral = fields.commentaire_general;
        this.valide = fields.valide;
        this.rapport = fields.rapport;
        this.isContradictory = fields.is_contradictory;
        this.alerteFournisseur = fields.alerte_prestataire;
        this.contradictoryPerson = fields.contradictory_person;
        this.contradictoryPersonDetail = fields.contradictory_person_detail;
    }
}

export interface Controle {
    [prop: string]: any;
}
