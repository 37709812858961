import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_URL} from '../../../environments/environment';
import {NetworkService, ConnectionStatus} from '../network.service';
import {OfflineManagerService} from '../offline-manager.service';
import {Storage} from '@ionic/storage';
import {Queue} from '../../models/queue';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable({providedIn: 'root'})
export class Api {
    uploads = `${SERVER_URL}/uploads/`;
    url = `${SERVER_URL}/api/`;

    constructor(public http: HttpClient,
                private networkService: NetworkService,
                private offlineManager: OfflineManagerService,
                private storage: Storage) {
    }

    async get(endpoint: string, params?: any, reqOpts?: any) {
        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams()
            };
        }

        // Support easy query params for GET requests
        if (params) {
            reqOpts.params = new HttpParams();
            for (const k in params) {
                reqOpts.params = reqOpts.params.set(k, params[k]);
            }
        }

        if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
            // Return the cached data from Storage
            console.log('Import from cache');
            try {
                return await this.getLocalData(endpoint);
            }
            catch (e) {
            }
        }

        return await this.http.get(this.url + endpoint, reqOpts).toPromise().then(res => {
            this.setLocalData(endpoint, res);
            return res;
        });
    }

    async post(endpoint: string, body: any, reqOpts?: any) {
        if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
            return await this.queueRequest('POST', endpoint, body, reqOpts);
        }
        return await this.http.post(this.url + endpoint, body, reqOpts).toPromise();
    }

    async put(endpoint: string, body: any, reqOpts?: any) {
        if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
            return await this.queueRequest('PUT', endpoint, body, reqOpts);
        }
        return await this.http.put(this.url + endpoint, body, reqOpts).toPromise();
    }

    async delete(endpoint: string, reqOpts?: any) {
        if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
            return await this.queueRequest('DELETE', endpoint, null, reqOpts);
        }
        return await this.http.delete(this.url + endpoint, reqOpts).toPromise();
    }

    async patch(endpoint: string, body: any, reqOpts?: any) {
        if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
            return await this.queueRequest('PATCH', endpoint, body, reqOpts);
        }
        return await this.http.patch(this.url + endpoint, body, reqOpts).toPromise();
    }

    private async queueRequest(method: string, endpoint: string, body?: any, reqOpts?: any) {
        const queueItem = new Queue(method, endpoint, body, reqOpts);

        await this.offlineManager.addToQueue(queueItem);
    }

    // Save result of API requests
    private setLocalData(key, data) {
        this.storage.get('refreshToken').then(refreshToken => {
            this.storage.set(refreshToken + '-' + key, data);
        });
    }

    // Get cached API result
    private async getLocalData(key) {
        this.storage.get('refreshToken').then(refreshToken => {
            return this.storage.get(refreshToken + '-' + key);
        });
    }
}
