export class Zone {
  reference : any ;
  quantite: number ;

  constructor(fields: any) {
    this.reference = fields.reference;
    this.quantite = fields.quantite;
  }
}

export interface Zone {
    [prop: string]: any;
}
