export class Fournisseur {
  id : any ;

  constructor(fields: any) {
    this.id = fields.id;
  }
}

export interface Fournisseur {
    [prop: string]: any;
}
